import React, { memo, useState } from 'react';
import { Modal } from 'antd';
import styled from '@emotion/styled';
import DOMPurify from 'isomorphic-dompurify';

import theme from '../theme';
import { DEFAULT_MODAL_WIDTH } from '../constants';
import { Button } from '../components/index';

const Body = styled.div`
  font-size: 20px;
  margin-bottom: 10px;
  color: ${theme.colorsBlack};
  max-height: 65vh;
  overflow-y: scroll;
`;

type ModalProps = {
  title: string;
  description: any;
};

export const CareersDetail = memo(function ViewFlagModal({ title, description }: ModalProps) {
  const [visibleModal, setVisibleModal] = useState<boolean>(false);
  return (
    <>
      <Button
        style={{ fontSize: '18px' }}
        type="link"
        onClick={() => {
          setVisibleModal(true);
        }}
      >
        Read More
      </Button>
      <Modal
        width={DEFAULT_MODAL_WIDTH}
        title={title}
        visible={visibleModal}
        centered
        closable={true}
        onCancel={() => {
          setVisibleModal(false);
        }}
        footer={[
          <Button
            key="close"
            type="primary-blue"
            onClick={() => {
              setVisibleModal(false);
            }}
          >
            Close
          </Button>,
        ]}
      >
        <Body
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(description),
          }}
        />
      </Modal>
    </>
  );
});
