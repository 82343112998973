import { BasicContainer, PositionWrapper } from './basic-styles';
import { HSFCareersObject, HSFPosition } from '../types/cms/models/hsf-careers';
import { Link, graphql } from 'gatsby';

import { CareersDetail } from './hsf-career-modal';
import DOMPurify from 'isomorphic-dompurify';
import PageHeader from '../components/common/page-header';
import PublicLayout from '../layout/public-layout';
import React from 'react';
import { programShortName } from '../siteContent';
import styled from '@emotion/styled';
import theme from '../theme';
import { useI18NContext } from '../i18n';

type HsfCareersProps = {
  data: {
    hsfCareers: HSFCareersObject;
    hsfPositions: HSFPosition;
    navigationItem: any;
  };
  pageContext: any;
};

export const HsfCareersTitle = styled.div`
  font-weight: bold;
  font-size: 28px;
  color: #2b2b2b;
  margin-bottom: 20px;
  @media (max-width: ${theme.screenMd}) {
    padding: 0 24px;
  }
`;

const HsfCareersDescription = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 25px;
  p {
    letter-spacing: 0;
    line-height: 28px;
    padding-bottom: 16px;
    font-size: 18px;
    color: ${theme.colorsBlack};
    .video-wrapper {
      overflow: hidden;
      display: block;
      text-align: center;
      padding: 24px 0;
      @media (max-width: ${theme.screenMd}) {
        width: 100%;
        height: 40vh;
        iframe {
          width: 100% !important;
          height: 100% !important;
        }
      }
    }
  }
  @media (max-width: ${theme.screenMd}) {
    padding: 0 24px;
  }
`;

export const HsfJobsSectionTitle = styled.div`
  font-weight: bold;
  font-size: 23px;
  color: #2b2b2b;
  @media (max-width: ${theme.screenMd}) {
    padding: 0 24px;
  }
`;

const JobTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 15px;
  color: ${theme.colorsBlack};
`;

const JobDescriptionWrapper = styled.div`
  font-size: 18px;
  line-height: 28px;
  text-overflow: ellipsis;
  color: ${theme.colorsBlack};
`;

export const pageQuery = graphql`
  query($language: String) {
    navigationItem: allDirectusHsfCareersType(filter: { page: { id: { ne: null } }, status: { eq: "published" } }) {
      nodes {
        name
        translation(language: $language) {
          value
        }
        slug
      }
    }

    hsfCareers: allDirectusHsfCareer(filter: { status: { eq: "published" } }) {
      nodes {
        translation(language: $language) {
          hsf_careers_headline
          hsf_careers_description
          hsf_positions_section_title
        }
      }
    }

    hsfPositions: allDirectusHsfCareersPosition(filter: { status: { eq: "published" } }) {
      nodes {
        translation(language: $language) {
          position_title
          position_department
          position_description
        }
      }
    }
  }
`;

const positionDescriptionLength = 400;

const HsfCareers = ({ data: { hsfCareers, hsfPositions, navigationItem } }: HsfCareersProps) => {
  const { translateSlug } = useI18NContext();
  const careersPage = hsfCareers.nodes;
  const hsfJobs = hsfPositions.nodes;
  return (
    <PublicLayout seoTitle={`${programShortName} Careers`}>
      {careersPage ? (
        <BasicContainer>
          <PageHeader
            title=""
            breadcrumb={[
              <Link key="1" to={translateSlug('/')}>
                Home
              </Link>,
              navigationItem?.nodes[0].translation?.value,
            ]}
            fullWidth
            isResponsive
            noShadow
          />
          <HsfCareersTitle>{navigationItem?.nodes[0].translation?.value || ''}</HsfCareersTitle>
          <HsfCareersDescription
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(careersPage[0].translation?.hsf_careers_description, { ADD_TAGS: ['iframe'], ADD_ATTR: ['target'] }),
            }}
          />
          <HsfJobsSectionTitle>{careersPage[0].translation?.hsf_positions_section_title || ''}</HsfJobsSectionTitle>
          {hsfJobs &&
            hsfJobs.map((item: any, index: number) => {
              return (
                <PositionWrapper key={index}>
                  <JobTitle>
                    {item.translation?.position_title || ''}, {item.translation?.position_department || ''}
                  </JobTitle>
                  <JobDescriptionWrapper>
                    {item.translation?.position_description.length < positionDescriptionLength ? (
                      <div
                        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.translation?.position_description) }}
                      />
                    ) : (
                      <>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(
                              `${item.translation?.position_description.slice(0, positionDescriptionLength)}...`
                            ),
                          }}
                        />
                        <CareersDetail
                          title={`${item.translation?.position_title}, ${item.translation?.position_department}`}
                          description={item.translation?.position_description}
                        />
                      </>
                    )}
                  </JobDescriptionWrapper>
                </PositionWrapper>
              );
            })}
        </BasicContainer>
      ) : (
        <BasicContainer />
      )}
    </PublicLayout>
  );
};

export default HsfCareers;
